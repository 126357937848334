import React from 'react';
import { useSelector } from 'react-redux';
import { DataTable } from '../reusable/table/DataTable';
import { StudentSemesterColumn } from './studentTable/StudentSemesterColumn';
import {transformStudentMarks,filterColumns} from '../../utils/students-marks'

const StudentSemMarks = () => {
  const { studentDetail } = useSelector((state) => state.getStudentDetail);

  const studentMarks = studentDetail?.student_marks ?? [];
  

  const transformedMarks = transformStudentMarks(studentMarks);
  const filteredColumns = filterColumns(transformedMarks, StudentSemesterColumn);

  return (
    <div className="grid col-span-2 border-2 rounded-lg shadow-lg px-6 py-4">
      {studentMarks.length > 0 ? (
        <DataTable hasPagination={false} hasClick={false} columns={filteredColumns} data={transformedMarks} />
      ) : (
        <p className="text-gray-500">Semester marks data not available.</p>
      )}
    </div>
  );
};

export default StudentSemMarks;
