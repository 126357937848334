import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'

export const updatePrivacyPolicy = createAsyncThunk('updatePrivacyPolicy', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.patch(`${process.env.REACT_APP_BACKEND_URL}/api/student/privacy-policy/${obj.studentId}?type=${obj.type}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})
const updatePrivacyPolicySlice = createSlice({
    name: "Update Private Policy",
    initialState: {
        loading: false,
        error: null,
        successMsg: null
    },
    reducers: {
        resetPrivacyPolicySuccessMsg: (state, action) => {
            state.successMsg = null
        },
        resetPrivacyPolicyError: (state, action) => {
            state.error = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updatePrivacyPolicy.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(updatePrivacyPolicy.fulfilled, (state, action) => {
            state.loading = false;
            state.successMsg = "Privacy Policy Updated Successfully"
        })
        builder.addCase(updatePrivacyPolicy.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload || 'Failed to update privacy policy';
        })
    }
})

export const { resetPrivacyPolicySuccessMsg, resetPrivacyPolicyError } = updatePrivacyPolicySlice.actions
export default updatePrivacyPolicySlice.reducer