import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const scheduleDriveCompaniesList = createAsyncThunk('scheduleDriveCompaniesList', async (_, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/drive/schedule-drive/request`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const companiesPointOfContact = createAsyncThunk('companiesPointOfContact', async (obj, { rejectWithValue }) => {
   
    try {
        if (obj.companyId === '') {
            return null
        }
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/drive/schedule-drive/contact?id=${obj.companyId}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const scheduleDriveCompaniesListSlice = createSlice({
    name: 'studentPlacedCount',
    initialState: {
        loading: false,
        scheduleCompanies: [],
        poc: null,
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(scheduleDriveCompaniesList.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(scheduleDriveCompaniesList.fulfilled, (state, action) => {
            state.loading = false;
            state.scheduleCompanies = action.payload
            state.poc = null
        })
        builder.addCase(scheduleDriveCompaniesList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
        builder.addCase(companiesPointOfContact.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(companiesPointOfContact.fulfilled, (state, action) => {
            state.loading = false;
            state.poc = action.payload
        })
        builder.addCase(companiesPointOfContact.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export default scheduleDriveCompaniesListSlice.reducer