import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'

export const fetchCompanies = createAsyncThunk('fetchCompanies', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/company?location=${obj.location || ''}&branch=${obj.branch || ''}&name=${obj.name || ''}&status=${obj.status || ''}&limit=${obj.limit || 10}&offset=${obj.offset || 0}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const companySlice = createSlice({
    name: 'company',
    initialState: {
        loading: false,
        isLoadingMore: false,
        hasMore: true,
        companies: {
            result: [], 
            count: 0
        },
        searchCompanyName: "",
        offset: 0,
        error: null,
    },
    reducers : {
        updateSearchCompanyName: (state,action) => {
            state.searchCompanyName = action.payload
        },
        resetCompanies: (state) => {
            state.companies.result = []; 
            state.companies.count = 0; 
            state.offset = 0;
            state.hasMore = true;
        },
        incrementOffset: (state) => {
            state.offset += 10;
          },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCompanies.pending, (state, action) => {
            if (action.meta.arg.offset > 0) {
                state.isLoadingMore = true; 
              } else {
                state.loading = true; 
              }
        })
        .addCase(fetchCompanies.fulfilled, (state, action) => {
            state.companies.result = [...state.companies.result || [], ...action.payload.result];
            state.companies.count = action.payload.count;
            state.loading = false;
            state.isLoadingMore = false;
            state.hasMore = action.payload.result.length >= action.meta.arg.limit;
        })
        
        builder.addCase(fetchCompanies.rejected, (state, action) => {
            state.loading = false;
            state.isLoadingMore = false; 
            state.error = action.payload
        })
    }
})

export const {updateSearchCompanyName,resetCompanies,incrementOffset} = companySlice.actions
export default companySlice.reducer