import "./App.css";
import Home from "./Pages/Home";
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "./components/Login/Login";
import Register from "./components/Login/Register";
import StudentProfile from "./components/students/StudentDetails";
import StudentDirectory from "./Pages/StudentDirectory";
import { useEffect, useMemo, useState } from "react";
import { Context } from "./context/Context";
import Option from "./components/Login/Option";
import CompaniesDirectory from "./Pages/CompaniesPage";
import CompanyProfile from "./components/Companies/CompanyProfile";
import CompanyInvitation from "./components/Companies/CompanyInvitation";
import CompaniesPlacementCalendar from "./components/Companies/CompaniesPlacementCalendar";
import CorporateNetwork from "./components/Companies/CorporateNetwork";
import SettingsPage from "./Pages/SettingsPage";
import ReportsPage from "./Pages/ReportsPage";
import VerifyEmail from "./components/Login/VerifyEmail ";
import Forgot from "./components/Login/Forgot";
import { Message } from "./components/Message";
import { useDispatch, useSelector } from "react-redux";
import DrivePage from "./Pages/DrivePage";
import DriveDetails from "./components/drives/DriveDetails";
import MyTeam from "./components/profile/MyTeam";
import { getAuth } from "firebase/auth";
import { setToken } from "./redux/features/firebaseAuthtoken";
import { firebaseSetUser } from "./redux/features/loginSlice";
import { auth } from "./config/firebaseConfig";
import axiosInstance from "./headersService";
import Jobs from "./components/jobs/JobTable/JobTable";
import ProtectedRoute from "./config/ProtectedRoute";
import JobsPage from "./Pages/JobsPage";
import JobDetail from "./components/jobs/JobDetail";
import ViewRegistration from "./components/drives/ViewRegistration";
import Sidebar from "./components/Sidebar";
import Dashboard from "./components/Dashboard";
import DriveRoundDetails from "./components/drives/DriveRoundDetails";
import HomeLoading from "./components/HomeLoading";
import CompanyDetails from "./components/Companies/CompanyDetails";
import { Toaster } from "./components/ui/sonner";
import DriveAnnouncements from "./components/drives/DriveAnnouncements";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { updateStudentDetails } from "./redux/features/studentDetailSlice";
import { logoutUser } from "./redux/features/loginSlice";
import ReactGA from 'react-ga4';
import { ImageProvider } from "./context/ImageContext";
import activeStudentIcon from '../src/assets/sidebarIcons/activeStudentIcon.png'
import activeCompaniesIcon from '../src/assets/sidebarIcons/activeCompaniesIcon.png'
import activeDriveIcon from '../src/assets/sidebarIcons/activeDriveIcon.png'
import profile from "../src/assets/profilePic.png";
import imageUnavailable from "./assets/icons/image_unavailable.png"
import {updatePrivacyPolicy} from "./redux/features/updatePrivacyPolicySlice";

// Initialize Google Analytics only in production
(window.location.hostname === "college.joinsaarthi.com" ||
  window.location.hostname === "www.college.joinsaarthi.com") &&
  ReactGA.initialize("G-YFR6MEJKQ2");

function App() {
  // Track the initial page load only in production
  (window.location.hostname === 'college.joinsaarthi.com' || window.location.hostname === 'www.college.joinsaarthi.com') && ReactGA.send({ hitType: 'pageview', page: window.location.pathname });


  const [toggleOn, setToggleOn] = useState(false);
  const [search, setSearch] = useState("");
  const [companiesMenu, setCompaniesMenu] = useState(false);
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(null); // Initially, we don't know if the policy is accepted
  const [showPopover, setShowPopover] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.loginReducer)

  const [loading, setLoading] = useState(true);
  const {
    user: currentUser,
    loginSuccess,
    signUpType,
  } = useSelector((state) => state.loginReducer);

  const fallbacks = {
    student: profile,
    company: activeCompaniesIcon,
    drive: activeDriveIcon,
    profile: profile,
    default: imageUnavailable
  };

  const checkUser = () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const response = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/tpo/my-profile`);
          console.log(response)
          const { privacy_policy_accepted_on } = response.data;

          if (privacy_policy_accepted_on) {
            setIsPolicyAccepted(true);
            navigate("/");
          } else {
            // If the policy is not accepted, show the popover
            setShowPopover(true);
          }
          dispatch(firebaseSetUser(response.data));

          setLoading(false);
        } catch (error) {
          // Handle any errors here
          console.log(error);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    checkUser();
  }, [dispatch]);

  if (loading) {
    return <HomeLoading />;
  }
  if (showPopover) {
    return (
      <PrivacyPolicy
        open={showPopover}
        onOpenChange={setShowPopover}
        onAccept={async () => {
          try {
            dispatch(updatePrivacyPolicy({  
              studentId: user?.id,
              type: 'TPO',
             }))
            setShowPopover(false);
          } catch (error) {
            console.error(error);
          }
        }}
        onDecline={async () => {
          dispatch(logoutUser()); 
          setShowPopover(false); 
        }}
      />
    );
  }
  
  

  return (
    <Context.Provider
      value={{
        toggleOn,
        setToggleOn,
        search,
        setSearch,
        companiesMenu,
        setCompaniesMenu,
      }}
    >
      <ImageProvider fallbacks={fallbacks}>
        <Message />
        <Toaster richColors />
        <Routes >
          {/* <Route element={<ProtectedRoute />}>
          <Route exact path="/invitationstatus" element={<CompanyInvitation />} />
          <Route exact path="/placementcalendar" element={<CompaniesPlacementCalendar />} />
          <Route exact path={`/auth/action`} element={<VerifyEmail />} />
          <Route exact path="/drive/drive-details/:id/view-registration" element={<ViewRegistration />} />
        </Route> */}
          <Route element={<ProtectedRoute />}>
            <Route
              path="/"
              element={<Dashboard />}
              children={[
                <Route path="/" exact element={<Home />} />,
                <Route exact path="/student" element={<StudentDirectory />} />,
                <Route exact path="/companies" element={<CompaniesDirectory />} />,
                <Route exact path="/corporatenetwork" element={<CorporateNetwork />} />,
                <Route exact path="/drive/:type" element={<DrivePage />} />,
                <Route exact path="/drive/drive-details/:id" element={<DriveDetails />} />,
                <Route exact path="/jobs" element={<JobsPage />} />,
                <Route exact path="/jobs/:jobId" element={<JobDetail />} />,
                <Route exact path="/companies/:id" element={<CompanyDetails />} />,
                <Route exact path="/student/:id" element={<StudentProfile />} />,
                <Route exact path="/drive/drive-details/:id/rounds/:roundId" element={<DriveRoundDetails />} />,
                <Route exact path="/drive/drive-details/:id/announcements" element={<DriveAnnouncements />} />,
                <Route exact path="/my-profile" element={<SettingsPage />} />,
                <Route exact path="/my-team" element={<MyTeam />} />,
                <Route exact path="/reports" element={<ReportsPage />} />
              ]}
            />

          </Route>
          <Route exact path="*" element={<h1>The page you are looking for could not be found please go back</h1>} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/option" element={<Option />} />
          <Route exact path="/forgot-password" element={<Forgot />} />
        </Routes>
      </ImageProvider>
    </Context.Provider>
  );
}

export default App;
