import { useSelector } from "react-redux"
import CommonModal from "./reusable/modals/CommonModal"
import { Copy, Share2 } from "lucide-react"
import { useState } from "react"
import { Button } from "./ui/button"
import { Link } from "react-router-dom"

const SharingModal = () => {

    const [isCopied, setIsCopied] = useState(false)
    const { user } = useSelector(state => state.loginReducer)
    const jobFormLink = `${process.env.REACT_APP_COMPANY_URL}/job-form/${user.institutionId}`

    const copyLink = () => {
        navigator.clipboard.writeText(jobFormLink)
        setIsCopied(true)

        setTimeout(() => {
            setIsCopied(false)
        }, 2000);
    }
    const triggerButton = <Button variant="primary" className="inline-flex items-center gap-x-2">
        <Share2 size={16} />
        Share
    </Button>
    return (
        <div>
            <CommonModal title={"Share Job form"} triggerButton={triggerButton} >
                <div className="flex flex-col gap-y-4">
                    <div className="flex items-center h-[40px] border rounded-md">
                        <div className="w-[70%] h-full flex items-center px-4 text-sm text-primaryTextColorLight border border-l-0 border-t-0 border-b-0 border-r">
                            <p className="line-clamp-1">{jobFormLink}</p>
                        </div>
                        <div onClick={copyLink} className="w-[30%] h-full flex items-center justify-center font-medium text-secondaryTextColor cursor-pointer gap-x-2 ">
                            <Copy size={16} />
                            <p className="text-sm">{isCopied ? "Copied" : "Copy"}</p>
                        </div>
                    </div> 
                </div>
            </CommonModal>
        </div>
    )
}
export default SharingModal