import CustomImage from "../../CustomImage";
import { Button } from "../../ui/button";
import CompanyConnectedActions from "../CompanyConnectedActions";
import CompanyInvitationModal from "../CompanyInvitationModal";
import CompanyModal from "../CompanyModal";
import CompanyViewInvitation from "../CompanyViewInvitation";
import CompanyWithdraw from "../CompanyWithdraw";
import DeleteInviteModal from "../DeleteInviteModal";

export const statusEnum = {
  APPROACHED_BY_COLLEGE: "Approached",
  APPROACHED_BY_COMPANY: "Approached By Company",
  ACCEPTED: "Accepted",
  REJECTED_BY_COLLEGE: "Rejected By College",
  REJECTED_BY_COMPANY: "Rejected By Company",
  NOT_CONNECTED: null,
};

export const companyDataColumns = [
  {
    accessorKey: "logo",
    header: "Logo",
    cell: ({ row }) => {
      const company = row.original;
      return (
        <CustomImage
          className="w-12 h-12 object-contain rounded-full"
          src={company.logoUrl}
          alt="Company Image"
          section="company"
        />
      );
    },
  },
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    accessorKey: "typeOfHires",
    header: "Hiring From",
    cell: ({ row }) => {
      if (row.original.typeOfHires.length < 3) {
        return row.original.typeOfHires.join(", ");
      }
      return (
        <span className="flex">
          {row.original.typeOfHires.slice(0, 2).join(", ")},{" "}
          <p className="underline text-neutral-600">
            +{row.original.typeOfHires.length - 2} more
          </p>
        </span>
      );
    },
  },
  {
    accessorKey: "location",
    header: "Location",
  },
  {
    id: "invitation",
    cell: ({ row }) => {
      const company = row.original;

      const handleInviteButtonClick = (event) => {
        event.stopPropagation();
      };

      const connection = company.connection;

      if (connection) {
        switch (connection.connectionStatus) {
          case statusEnum.APPROACHED_BY_COMPANY:
            return <CompanyViewInvitation connectionId={connection.id} />;

          case statusEnum.APPROACHED_BY_COLLEGE:
            return <CompanyWithdraw connectionId={connection.id} />;

          case statusEnum.ACCEPTED:
            return (
              <Button
                variant="primary"
                size="md"
                className="bg-[#195D30] shadow-md text-base hover:bg-[#195D30]/70 "
              >
                Connected
              </Button>
            );

          case statusEnum.REJECTED_BY_COLLEGE:
            return <DeleteInviteModal connectionId={connection.id} />;

          case statusEnum.REJECTED_BY_COMPANY:
            return (
              <Button variant="primary" className="shadow-md text-base">
                Invitation Rejected
              </Button>
            );
          default:
            return null;
        }
      } else {
        return <CompanyInvitationModal companyId={company.id} />;
      }
    },
  },
  {
    id: "actions",
    header: "Action",
    cell: ({ row }) => {
      const company = row.original;
      if (
        company.connection &&
        company.connection?.connectionStatus === statusEnum.ACCEPTED
      ) {
        return (
          <CompanyConnectedActions
            companyName={company.name}
            connectionId={company.connection.id}
            pocName={company.pointOfContact.name}
            pocEmail={company.pointOfContact.email}
            pocId={company.pointOfContactId}
          />
        );
      }
    },
  },
];
