import * as React from "react";
import { useNavigate } from "react-router-dom";
import Profile from "./Profile";
import { fetchStudentDetails } from "../../redux/features/studentDetailSlice";
import { useDispatch, useSelector } from "react-redux";
import * as Tabs from '@radix-ui/react-tabs';
import './SettingsMainStyles.css';
import { IoArrowBackCircle } from "react-icons/io5"
import SignInAndSecurity from "./SignInAndSecurity";
import Privacy from "./Privacy";
import Terms from "./Terms";


export default function SettingsMain(props) {

  const navigate = useNavigate()
  const dispatch = useDispatch();


  return (
    <>
      <div className="flex items-center ml-10 mr-10 my-2 bg-cardBgColor h-[4.5rem] rounded-lg shadow-md text-2xl relative">
        <div onClick={() => navigate(-1)}>
          <IoArrowBackCircle size={50} className="text-primaryTextColor cursor-pointer hover:text-primaryTextColor/80 absolute -left-5 -top-2" />
        </div>
        <div className="ml-10 text-2xl font-semibold">My Account</div>
      </div>

      <Tabs.Root className="ml-10 mr-10 my-5" defaultValue="personalInfoTab">
        <Tabs.List className="TabsList w-3/4">
          <Tabs.Trigger className="TabsTrigger" value="personalInfoTab">
            Personal Info
          </Tabs.Trigger>
          <Tabs.Trigger className="TabsTrigger" value="accountSettingsTab">
            Account Settings
          </Tabs.Trigger>
          <Tabs.Trigger className="TabsTrigger" value="securityTab">
            Sign in & Security
          </Tabs.Trigger>
          <Tabs.Trigger className="TabsTrigger" value="privacyTab">
            Privacy
          </Tabs.Trigger>
          <Tabs.Trigger className="TabsTrigger" value="termsTab">
            Terms & Conditions
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content className="TabsContent" value="personalInfoTab">
          <Profile />
        </Tabs.Content>
        <Tabs.Content className="TabsContent" value="accountSettingsTab">
          Account Settings
        </Tabs.Content>
        <Tabs.Content className="TabsContent" value="securityTab">
          <SignInAndSecurity />
        </Tabs.Content>
        <Tabs.Content className="TabsContent" value="privacyTab">
          <Privacy/>
        </Tabs.Content>
        <Tabs.Content className="TabsContent" value="termsTab">
         <Terms/>
        </Tabs.Content>
      </Tabs.Root>



    </>

  );
}
