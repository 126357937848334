import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyTeamDetails } from "../../redux/features/getMyTeamDetails";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import MemberDetailsLoading from "./MemberDetailsLoading";
import { Checkbox } from "../ui/checkbox";
import {
  resetTeamUpdateError,
  resetTeamUpdateSuccess,
  updateTeamPermissions,
} from "../../redux/features/teamManagePermissions";
import { handleSnackbar } from "../../redux/features/snackbarSlice";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { getCollegeBranches } from "../../redux/features/getCollegeBranch";
import CommonSelect from "../reusable/dropdown/CommonSelect";
import MultipleSelector from "../ui/multi-selector";
const MemberDetails = ({ memberId }) => {
  const { teamDetails, loading } = useSelector((state) => state.myTeamDetails);
  const { user } = useSelector((state) => state.loginReducer);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [designation, setDesignation] = useState("")
  const {
    success,
    loading: permissionUpdateLoader,
    error: updatePermissionError,
  } = useSelector((state) => state.managePermissions);
  const { branch } = useSelector((state) => state.collegeBranchReducer);
 
  const isAdmin =
    user?.permission_manage_users &&
    user?.permission_manage_drives &&
    user?.permission_manage_networks;
  const [permission, setPermission] = useState({
    usersPermission: null,
    drivesPermission: null,
    networksPermission: null,
    adminRights: null,
  });

  const dispatch = useDispatch();

  const handleTeamDetails = () => {
    dispatch(getMyTeamDetails({ type: user?.type, id: memberId }));
    dispatch(getCollegeBranches());
  };

  useEffect(() => {
    if (teamDetails) {
      setPermission({
        usersPermission: teamDetails?.permission_manage_users,
        drivesPermission: teamDetails?.permission_manage_drives,
        networksPermission: teamDetails?.permission_manage_networks,
        adminRights: teamDetails?.adminRights,
      });
    }
  }, [teamDetails]);

  const handlePermissionChange = (name, value) => {
    if (name === "adminRights") {
      setPermission({
        usersPermission: !!value,
        drivesPermission: !!value,
        networksPermission: !!value,
        adminRights: !!value,
      });
    } else {
      setPermission((prevPermission) => ({
        ...prevPermission,
        [name]: value,
      }));
    }
  };

  const handleUpdate = () => {
    const body = {
      permission_manage_users: permission.usersPermission,
      permission_manage_drives: permission.drivesPermission,
      permission_manage_networks: permission.networksPermission,
      branch: selectedBranch.map((branch) => branch.value), 
      designation: designation
    };
  
    dispatch(updateTeamPermissions({ body, tpoId: memberId }));
  };

  const handleUpdateSuccess = () => {
    dispatch(
      handleSnackbar({
        open: true,
        message: "Permissions Updated Successfully",
        type: "success",
      })
    );
    dispatch(resetTeamUpdateSuccess());
  };
  const handleUpdateError = () => {
    dispatch(
      handleSnackbar({
        open: true,
        message: updatePermissionError,
        type: "error",
      })
    );
    dispatch(resetTeamUpdateError());
  };

  useEffect(() => {
    if (success) {
      handleUpdateSuccess();
    }
    if (updatePermissionError) {
      handleUpdateError();
    }
  }, [success, updatePermissionError]);

  return (
    <div>
      <Dialog>
        <DialogTrigger asChild>
          {isAdmin && (
            <Button
              variant="secondary"
              className="shadow-md text-base text-[#9B0501]"
              onClick={handleTeamDetails}
            >
              Click Here
            </Button>
          )}
        </DialogTrigger>
        <DialogContent className="sm:max-w-[500px] bg-[#F5F4F4]">
          <DialogHeader>
            <DialogTitle>Access Details</DialogTitle>
          </DialogHeader>
          {loading ? (
            <MemberDetailsLoading />
          ) : (
            <div className="flex flex-col gap-y-4">
              <div className="flex flex-row items-center gap-1">
                <Label htmlFor="name" className="w-24 text-left">
                  Name
                </Label>
                <Input id="name" disabled={true} value={teamDetails?.name} className="flex-1" />
              </div>
              <div className="flex flex-row items-center gap-1 mt-4">
                <Label htmlFor="email" className="w-24 text-left">
                  Email
                </Label>
                <Input
                  id="email"
                  value={teamDetails?.email}
                  disabled={true}
                  className="flex-1"
                />
              </div>
              <div className="flex flex-row items-center gap-1 mt-4">
                <Label htmlFor="designation" className="w-24 text-left">
                  Designation
                </Label>
                <Input
                  id="designation"
                  value={teamDetails?.designation}
                  className="flex-1"
                  onChange={(e) => setDesignation(e.target.value)}
                />
              </div>

              {permission.usersPermission &&
                !permission.drivesPermission &&
                !permission.networksPermission &&
                !permission.adminRights && (
                  <div className="flex flex-col items-start space-y-2">
                    <Label htmlFor="branch">Select Branch for Permission</Label>
                    <MultipleSelector
                      placeholder="Select Branch"
                      value={selectedBranch}
                      onChange={(selectedValues) => setSelectedBranch(selectedValues)}
                      defaultOptions={branch.map((branch) => ({ label: branch, value: branch }))}
                      emptyIndicator={
                        <p className="text-center text-lg leading-10 text-gray-600 dark:text-gray-400">
                          No branches available.
                        </p>
                      }
                      badgeClassName="bg-primaryTextColor text-white hover:bg-primaryTextColor/80"
                      className="bg-white"
                    />
                  </div>
                )}
              <div className="flex items-center space-x-2">
                <Checkbox
                  checked={permission.usersPermission}
                  onCheckedChange={(value) =>
                    handlePermissionChange("usersPermission", value)
                  }
                  id="users"
                />
                <Label
                  htmlFor="users"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Manage Users
                </Label>
              </div>
              <div className="flex items-center space-x-2">
                <Checkbox
                  checked={permission.drivesPermission}
                  onCheckedChange={(value) =>
                    handlePermissionChange("drivesPermission", value)
                  }
                  id="drives"
                />
                <Label
                  htmlFor="drives"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Manage Drives
                </Label>
              </div>
              <div className="flex items-center space-x-2">
                <Checkbox
                  checked={permission.networksPermission}
                  onCheckedChange={(value) =>
                    handlePermissionChange("networksPermission", value)
                  }
                  id="networks"
                />
                <Label
                  htmlFor="networks"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Manage Networks
                </Label>
              </div>
              <div className="flex items-center space-x-2">
                <Checkbox
                  checked={permission.adminRights}
                  onCheckedChange={(value) =>
                    handlePermissionChange("adminRights", value)
                  }
                  id="admin"
                />
                <Label
                  htmlFor="admin"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Admin Rights
                </Label>
              </div>
            </div>
          )}
          <DialogFooter>
            <Button
              type="submit"
              variant="primary"
              className="rounded-3xl text-md shadow-lg"
              onClick={handleUpdate}
            >
              Save Changes
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default MemberDetails;
