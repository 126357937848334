import React from "react";
import { useSelector } from "react-redux";
import { statusEnum } from "../components/Companies/CompanyTable/companyColumns";
import CompanyViewInvitation from "../components/Companies/CompanyViewInvitation";
import CompanyWithdraw from "../components/Companies/CompanyWithdraw";
import { Button } from "../components/ui/button";
import DeleteInviteModal from "../components/Companies/DeleteInviteModal";
import CompanyInvitationModal from "../components/Companies/CompanyInvitationModal";

const CompanyConnectionButton = () => {
  const { company } = useSelector((state) => state.getCompanyDetail);
  const connection = company?.connection;
  const connectionStatus = connection?.connectionStatus;
  if (connectionStatus) {
    switch (connectionStatus) {
      case statusEnum.APPROACHED_BY_COMPANY:
        return <CompanyViewInvitation connectionId={connection.id} />;

      case statusEnum.APPROACHED_BY_COLLEGE:
        return <CompanyWithdraw connectionId={connection.id} />;

      case statusEnum.ACCEPTED:
        return (
          <Button
            variant="primary"
            size="md"
            className="bg-[#195D30] shadow-md text-base hover:bg-[#195D30]/70 "
          >
            Connected
          </Button>
        );

      case statusEnum.REJECTED_BY_COLLEGE:
        return <DeleteInviteModal connectionId={connection.id} />;

      case statusEnum.REJECTED_BY_COMPANY:
        return (
          <Button variant="primary" className="shadow-md text-base">
            Invitation Rejected
          </Button>
        );
      default:
        return null;
    }
  } else {
    return <CompanyInvitationModal companyId={company.id} />;
  }
};

export default CompanyConnectionButton;
