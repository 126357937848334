import { useContext, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import CompanyCard from "./CompanyCard";

import "../../styles/Company.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCompanies,
  updateSearchCompanyName,resetCompanies,incrementOffset  
} from "../../redux/features/companySlice";
import { fetchCompanyDropdown } from "../../redux/features/companyDropdownSlice";
import {
  setSelectedBranch,
  setSelectedLocation,
} from "../../redux/features/companyBranchAndLocationSlice";

import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import CompanyTable from "./CompanyTable";
import { companyDataColumns } from "./CompanyTable/companyColumns";
import CompanyLoading from "./CompanyLoading";
import { Search } from "lucide-react";
import { DataTable } from "../reusable/table/DataTable";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
const CompaniesList = () => {
  const { selectedBranch, selectedLocation } = useSelector(
    (state) => state.companyBranchLocationSet
  );
  const [location, setLocation] = useState(selectedLocation);
  const [branch, setBranch] = useState(selectedBranch);

  const [openLocationDropdown, setOpenLocationDropdown] = useState(false);
  const { openMenu } = useSelector((state) => state.openMenu);
  //getting all the connection to add condition to invite and invitation sent button

  const { user } = useSelector((state) => state.loginReducer);
  const isMobile = useMediaQuery({
    query: "(max-width: 590px)",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  //for handling location change
  const handleLocationChange = (value) => {
    setLocation(value);
    dispatch(setSelectedLocation(value));
    dispatch(setSelectedBranch(branch));
    dispatch(resetCompanies());
    dispatch(
      fetchCompanies({
        location: value,
        branch: branch,
        institutionId: user?.institutionId,
        limit,
        offset
      })
    );
  };

  //for handling branch change
  const handleBranchChange = (value) => {
    setBranch(value);
    dispatch(setSelectedBranch(value));
    dispatch(setSelectedLocation(location));
    dispatch(resetCompanies());
    dispatch(fetchCompanies({ branch: value, location: location, limit, offset }));
  };

  //obtaining the list of companies from the store
  const { companies, searchCompanyName, loading,offset,isLoadingMore,hasMore } = useSelector(
    (state) => state.getCompanies
  );
  const { result, count } = companies;
 
  const limit = 10;

  const { loading: addConnectionLoading } = useSelector(
    (state) => state.addConnection
  );
  //obtaining the company dropdown list from the store
  const { companyDropdown: branchDropdown } = useSelector(
    (state) => state.getCompanyBranches
  );
  const { companyLocationDropdown } = useSelector(
    (state) => state.getCompanyLocationDropdown
  );

  const timeoutRef = useRef(null);

  const handleSearchChange = (e) => {
    dispatch(updateSearchCompanyName(e.target.value));
    const debounceDelay = 500;

    // Clear previous timeout if exists
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    // Set new timeout
    timeoutRef.current = setTimeout(() => {
      handleSearchChange.timeoutId = setTimeout(() => {
        dispatch(
          fetchCompanies({
            branch: selectedBranch,
            location: selectedLocation,
            name: e.target.value,
          })
        );
      }, debounceDelay);
    }, debounceDelay);
  };

  const clearSearch = () => {
    dispatch(updateSearchCompanyName(""));
    dispatch(
      fetchCompanies({ branch: selectedBranch, location: selectedLocation })
    );
  };

  const loadMoreCompanies = () => {
    if (!loading && !isLoadingMore &&hasMore) {
      dispatch(incrementOffset());
      dispatch(
        fetchCompanies({
          branch: selectedBranch,
          location: selectedLocation,
          name: searchCompanyName,
          limit,
          offset
        })
      )
    }
  };

  const handleScroll = () => {
    const bottom =
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 100; 
    if (bottom) {
      loadMoreCompanies();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, isLoadingMore,offset,hasMore]);



  return (
    <div className="flex flex-col mt-3 w-full">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <h1 className="font-bold text-2xl">Companies</h1>
          <div className="flex bg-searchBgColor items-center justify-between border rounded-lg px-2 ">
            <Input
              onChange={handleSearchChange}
              className="font-light bg-transparent text-base border-none"
              placeholder="Search for Companies..."
            />
            <Search size={20} />
          </div>
        </div>
        <div className="flex items-center gap-x-4">
          <Select
            onValueChange={(value) => handleBranchChange(value)}
            defaultValue={branch}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Branch" />
            </SelectTrigger>
            <SelectContent>
              {branchDropdown?.map((branch, i) => {
                return (
                  <SelectItem key={i} value={branch.label}>
                    {branch.label}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
          <Select
            onValueChange={(value) => handleLocationChange(value)}
            defaultValue={location}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Location" />
            </SelectTrigger>
            <SelectContent>
              {companyLocationDropdown?.map((location, i) => {
                return (
                  <SelectItem key={i} value={location}>
                    {location}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="mt-2">
        {
          loading && !isLoadingMore ?
            <CompanyLoading /> : <DataTable hasPagination={false} hasClick={true} onClickRoute={(row) => navigate(`/companies/${row.id}`)} columns={companyDataColumns} data={result} />
        }
      </div>
      {!loading && isLoadingMore && (
        <div className="flex justify-center">
          <Loader />
        </div>
      )}
    </div>
  );
};
export default CompaniesList;
