import React from "react";

const Terms = () => {
  return (
    <div className="mx-14 p-6 text-justify">
      <h1 className="text-3xl font-bold mb-6 text-center underline">
        Terms of Use
      </h1>
      <h2 className="text-2xl font-semibold mb-4">1. PURPOSE</h2>
      <p className="mb-4">
        We urge you to read these terms or any section of interest to you. You
        are agreeing to proceed under them. By using the website, the user is
        considered to have accepted the terms and conditions listed below. If
        you do not agree to the terms and conditions of use below and/or any
        such terms and conditions of use as are communicated on the pages
        thereof, do not access this website, or any pages thereof. For the
        purpose of these Terms of Use, wherever the context so requires "You" or
        "User" shall mean any natural or legal person who has agreed to become a
        User of the Website by visiting website for any purpose or by providing
        Registration Data while registering on the Website as Registered User
        while assessing or feeding any information and or insertion or
        information or data into the computers, computer systems or computer
        network of Amaxa Tech LLP. Website allows the User to surf the Website
        or making purchases without registering on the Website. The term "We",
        "Us", "Our" shall mean Amaxa Tech LLP. Amaxa Tech Solutions is designed
        to serve as an innovative platform that facilitates the creation of
        professional and personal connections, allowing users to collaborate,
        share information, and network in the digital space. Our services are
        intended for legitimate purposes related to online networking,
        professional collaboration, and industry growth. The website{" "}
        <a href="https://college.joinsaarthi.com/" className="text-[#DA1F24]">
          www.college.joinsaarthi.com.
        </a>{" "}
        is intended only to serve as a platform of contact and exchange of
        information for its uses/visitors/members who have a bonafide intention
        to contact and/or to be contacted or the purpose related to genuine job
        vacancies and for career enhancement services. By impliedly or expressly
        accepting these Terms of Use, You also accept and agree to be bound by
        our Policies as amended from time to time.
      </p>
      <h2 className="text-2xl font-semibold mb-4">2. ELIGIBILITY</h2>
      <p className="mb-4">
        The Site is intended for users who are 18 years or older. By using this
        platform, you represent that you meet the eligibility criteria and agree
        to use the Site only for lawful purposes. You shall not use the Site for
        commercial purposes unless expressly permitted by Amaxa Tech Solutions.
      </p>
      <h2 className="text-2xl font-semibold mb-4">
        3. ACCOUNT AND REGISTRATION OBLIGATIONS
      </h2>
      <p className="mb-4">
        When using the Amaxa Tech Solutions website, you are responsible for
        maintaining the confidentiality of your Display Name, Username, and
        Password. You are also fully accountable for all activities that occur
        under your account credentials. If you provide any information that is
        false, inaccurate, outdated, or incomplete, or if we have reasonable
        grounds to suspect that such information is false, inaccurate, outdated,
        or incomplete, Amaxa Tech Solutions reserves the right to indefinitely
        suspend, terminate, or block your access to the website. Additionally,
        any such action on your part may have legal consequences, including
        civil and criminal liability. Furthermore, you agree to indemnify Amaxa
        Tech Solutions for any losses incurred as a result of any false,
        defamatory, libelous, vulgar, obscene, fraudulent, or otherwise
        objectionable content posted by you on our platform.
      </p>
      <h2 className="text-2xl font-semibold mb-4">4. COMMUNICATION</h2>
      <p className="mb-4">
        By using the Amaxa Tech Solutions website, you acknowledge and consent
        to receiving communications related to your activities on the platform.
        These may include messages, emails, or notifications. When you
        communicate with us electronically by sending emails or providing
        information, you agree that such communication constitutes an electronic
        record. You consent to receive communications from Amaxa Tech Solutions
        via email, SMS, phone calls, or other means, as deemed necessary. These
        communications may occur periodically or as required for service updates
        and notifications.
      </p>
      <h2 className="text-2xl font-semibold mb-4">
        5. USER RESPONSIBILITIES FOR USE OF THE WEBSITE
      </h2>
      <p className="mb-4">
        By creating a public account through our Website, You are expressly
        authorizing Amaxa Tech Solutions to make your profile available to any
        potential Employer, User, or individual who may have an interest in it.
        We may also recommend job listings to You through email or messages, and
        these recommendations could be based on the information You have
        provided on the Website. You agree that your use of the Website will be
        governed by the following principles:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          Access the Platform for purposes of extracting content to be used for
          training a machine learning or AI model, without the express prior
          written permission;
        </li>
        <li>
          Belongs to another person to which You do not have any legal right;
        </li>
        <li>
          Is harmful, harassing, defamatory, obscene, pornographic, invasive of
          privacy, racially or ethnically objectionable, or otherwise unlawful;
        </li>
        <li>Is misleading, offensive, or promotes illegal activities;</li>
        <li>
          Involves spamming, chain letters, or unsolicited mass communications;
        </li>
        <li>Infringes on third-party intellectual property rights;</li>
        <li>Promotes illegal conduct, violence, or exploitation;</li>
        <li>
          Contains personal information without consent, or solicits personal
          data from minors;
        </li>
        <li>Attempts unauthorized access to the Website or its systems;</li>
        <li>Impersonates another person or entity;</li>
        <li>
          Contains viruses or malicious software that can harm systems or data;
        </li>
        <li>
          Threatens the unity, integrity, security, or sovereignty of any
          country;
        </li>
        <li>
          Violates any applicable law, including those related to intellectual
          property and data protection;
        </li>
        <li>
          Engages in gambling or any activity that violates applicable laws;
        </li>
        <li>
          Misleads others about the origin of any communication or transmits
          offensive or menacing content;
        </li>
        <li>
          Engages in any commercial activity without prior written consent from
          Amaxa Tech Solutions;
        </li>
        <li>
          Attempt or breach security or authentication measures without proper
          authorization.
        </li>
      </ul>
      <h2 className="font-bold mb-2">System Misuse and Unauthorized Access</h2>
      <p className="mb-4">You agree not to:</p>
      <ul className="list-disc list-inside mb-4">
        <li>
          Use any automatic system, such as a "robot" or "spider," to access or
          extract data from the Website;
        </li>
        <li>
          Attempt to hack or gain unauthorized access to any part of the
          Website, or its associated systems and networks;
        </li>
        <li>
          Probe or test the Website for vulnerabilities, or breach its security
          or authentication systems;
        </li>
        <li>
          Engage in activities that place an unreasonable load on the Website
          infrastructure;
        </li>
        <li>
          Forge headers, manipulate identifiers, or disguise the origin of any
          communication;
        </li>
        <li>
          Use the Website for any illegal purpose or to solicit illegal
          activities.
        </li>
      </ul>
      <h2 className="font-bold mb-2">User Responsibilities and Content</h2>
      <p className="mb-4">
        You are solely responsible for any content You post or share on the
        Website. By submitting content, You grant Us a non-exclusive, worldwide,
        perpetual, irrevocable, royalty-free, sub-licensable right to use,
        reproduce, modify, distribute, and display such content. We may monitor
        and remove any content that violates these Terms, but You remain
        responsible for the material You post. You agree that you will be solely
        responsible for obtaining, at his own cost, all licenses, permits,
        consents, approvals and intellectual property or other rights as may be
        required by the user for using the Service. You agree that Amaxa Tech
        Solutions is not responsible for any unauthorized use of your
        information by third parties. We urge You to exercise caution when
        disclosing personal information on the Website. Although We strive to
        maintain a safe environment, We are not liable for any personal data You
        choose to share with others. You agree to comply with all applicable
        laws, including but not limited to the Information Technology Act, 2000,
        and other domestic and international regulations regarding the use of
        the Website. You also agree to grant Amaxa Tech Solutions the rights
        necessary to use any information You provide to Us, in line with our
        Privacy Policy. Amaxa Tech Solutions reserves the right to take
        necessary legal actions and claim damages if You are found to be
        involved in activities that attacks the Website or other harmful actions
        that disrupt the functioning of the Website.
      </p>
      <h2 className="text-2xl font-semibold mb-4">6. SUBSCRIPTION SERVICES</h2>
      <p className="mb-4">
        Certain premium services provided by Amaxa Tech Solutions, including but
        not limited to enhanced networking features, advanced analytical tools,
        personalized career guidance, or exclusive content, may require a
        subscription ("Subscription Services"). By opting for any Subscription
        Service, you agree to the following terms and conditions:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          Subscription fees for any premium services will be clearly
          communicated to you prior to purchase and are payable in the currency
          indicated at the time of purchase. Fees may vary based on the type of
          subscription plan chosen (e.g., monthly, quarterly, annually) and the
          services included. All fees are exclusive of applicable taxes unless
          otherwise specified, and you are responsible for the payment of any
          such taxes.
        </li>
        <li>
          All subscription fees are non-refundable once paid, regardless of the
          duration of the subscription or usage of the services. This includes
          situations where you choose to cancel the subscription before the end
          of the billing cycle.
        </li>
        <li>
          You authorize Amaxa Tech Solutions to charge the subscription fee to
          your chosen payment method (credit card, debit card, etc.) at the
          beginning of each subscription period. Recurring payments will be made
          automatically at the start of the next billing cycle unless you cancel
          the subscription in accordance with the cancellation policy.
        </li>
        <li>
          Unless you opt out of automatic renewal, your subscription will
          automatically renew at the end of each billing cycle at the
          then-current rate. You can manage your subscription settings,
          including canceling auto-renewal, by logging into your account on the
          Amaxa Tech Solutions website.
        </li>
        <li>
          Amaxa Tech Solutions reserves the right to modify the subscription
          fees for any future subscription periods. In the event of such
          changes, you will be notified via email or through your account
          dashboard. Continued use of the subscription after such notification
          will be deemed acceptance of the new rates.
        </li>
        <li>
          You may cancel your subscription at any time through your account
          settings on the website. However, cancellation will not affect charges
          already processed, and you will continue to have access to the
          subscription services until the end of the current billing period.
          After cancellation, you will not be billed for subsequent periods
          unless you re-subscribe.
        </li>
        <li>
          Amaxa Tech Solutions reserves the right to terminate or suspend your
          access to Subscription Services without notice if you breach these
          terms or any other policies. In such cases, no refunds will be issued,
          and you may be held liable for any damages resulting from the breach.
        </li>
        <li>
          Amaxa Tech Solutions may, at its sole discretion, modify or
          discontinue any aspect of the Subscription Services at any time. Any
          such changes will be communicated to you in advance, and if the
          modification materially reduces the value of the service, you may be
          entitled to terminate the subscription with a refund for the unused
          portion of the subscription period, subject to our discretion.
        </li>
        <li>
          From time to time, Amaxa Tech Solutions may offer free trials or
          promotional offers for its Subscription Services. The duration and
          terms of such offers will be specified at the time of the offer. Free
          trials are limited to one per user unless otherwise stated. At the end
          of a free trial period, your subscription will automatically convert
          to a paid subscription unless you cancel before the trial ends.
        </li>
        <li>
          Subscription Services provided by Amaxa Tech Solutions are personal to
          you and may not be assigned, transferred, or sublicensed to any third
          party without prior written consent.
        </li>
        <li>
          While Amaxa Tech Solutions makes every effort to ensure that
          Subscription Services are available at all times, we do not guarantee
          uninterrupted access. Occasional service interruptions may occur due
          to maintenance, updates, or factors beyond our control. In such
          events, Amaxa Tech Solutions shall not be held liable for any
          disruption to your Subscription Services, and no refunds will be
          issued for temporary outages.
        </li>
        <li>
          As a subscriber, you are responsible for maintaining the
          confidentiality of your account credentials and any activities that
          occur under your account. You must notify Amaxa Tech Solutions
          immediately if you suspect any unauthorized use of your subscription
          or account.
        </li>
      </ul>
      <h2 className="text-2xl font-semibold mb-4">7. DATA USAGE AND PRIVACY</h2>
      <p className="mb-4">
        By using Amaxa Tech Solutions, you consent to the collection and use of
        your data as outlined in our Privacy Policy. We use the data you provide
        to enhance user experience, make relevant networking recommendations,
        and improve our services. We do not sell or distribute your personal
        data to third parties without your consent, except as required by law or
        as described in our Privacy Policy.
      </p>
      <h2 className="text-2xl font-semibold mb-4">
        8. LIMITATION OF LIABILITY{" "}
      </h2>
      <p className="mb-4">
        Amaxa Tech Solutions disclaims all liability for any direct, indirect,
        incidental, or consequential damages arising from the use or inability
        to use the Site. This includes, but is not limited to, any loss of
        revenue, data, or other intangible losses, even if Amaxa Tech Solutions
        has been advised of the possibility of such damages.
      </p>
      <h2 className="text-2xl font-semibold mb-4">
        9. THIRD-PARTY SERVICES AND LINKS
      </h2>
      <p className="mb-4">
        Amaxa Tech Solutions may provide links to third-party websites or
        services. Engaging with third-party links is at the user's own risk.
        Amaxa Tech Solutions is not responsible for the actions, products, or
        content provided by third-party websites or affiliates that may be
        linked through our platform. We may provide links to these third-party
        services for convenience, but we do not warrant or endorse their
        offerings. Any user interaction with such third-party sites is at their
        own risk, and Amaxa Tech Solutions assumes no liability for the outcomes
        of these interactions.
      </p>
      <h2 className="text-2xl font-semibold mb-4">
        10. USER CONTENT GUIDELINES
      </h2>
      <p className="mb-4">
        All text, graphics, user interfaces, visual interfaces, photographs,
        trademarks, logos, sounds, music, and artwork (collectively referred to
        as "Content") on the Amaxa Tech Solutions website may include
        third-party user-generated content, over which Amaxa Tech Solutions has
        no control, as We act merely as an intermediary in accordance with these
        Terms of Use of Use. Except as expressly provided in these Terms of Use
        of Use, no part of the Website and no Content may be copied, reproduced,
        republished, uploaded, posted, publicly displayed, encoded, translated,
        transmitted, or distributed in any way (including "mirroring") to any
        other computer, server, website, or other medium for publication or
        distribution for any commercial purposes without Our prior express
        written consent.
      </p>
      <p className="mb-4">
        You may use information about the products and services specifically
        made available for downloading from the Website, provided that You:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          Do not remove any proprietary notice language in all copies of such
          documents;
        </li>
        <li>
          Use such information only for your personal, non-commercial,
          informational purposes and do not copy or post such information on any
          networked computer or broadcast it in any media;
        </li>
        <li>Make no modifications to any such information;</li>
        <li>
          Do not make any additional representations or warranties relating to
          such documents.
        </li>
      </ul>
      <h2 className="font-bold mb-2">User-Generated Content</h2>
      <p className="mb-4">
        You are solely responsible for any notes, messages, emails, postings,
        photos, drawings, profiles, opinions, ideas, images, videos, audio
        files, or other materials or information (collectively, "User Content")
        that You post or transmit to the Website. Such User Content becomes Our
        property, and by posting, You grant Us worldwide, perpetual,
        transferable rights to such Content. In accordance with applicable law
        and consistent with Our Privacy Policy, We may use this Content or any
        of its elements for promotional, advertising, or other purposes in any
        media, now known or hereafter devised, including the creation of
        derivative works that may incorporate the Content You provide. You agree
        that any User Content You post may be used by Us, in line with Our
        Privacy Policy and the Website's Rules of Conduct, and that You are not
        entitled to any compensation for such use.
      </p>
      <h2 className="font-bold mb-2">Content Restrictions</h2>
      <p className="mb-2">Users are prohibited from posting content that:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Infringes on third-party intellectual property rights.</li>
        <li>Contains false or misleading information.</li>
        <li>Promotes violence, hate speech, or illegal activities.</li>
      </ul>
      <h2 className="font-bold mb-2">Content Review</h2>
      <p className="mb-4">
        Amaxa Tech Solutions reserves the right to review, monitor, and remove
        any User Content it deems inappropriate, harmful, or in violation of
        these Terms of Use. This includes content that violates intellectual
        property rights or other legal standards.
      </p>
      <h2 className="text-2xl font-semibold mb-4">
        11. INTELLECTUAL PROPERTY RIGHTS
      </h2>
      <p className="mb-4">
        All trademarks, logos, service marks, and proprietary designs displayed
        on this website are the exclusive property of Amaxa Tech Solutions. Any
        unauthorized use, reproduction, or distribution of these materials
        without prior written consent is strictly prohibited. This Site is
        controlled and operated by Us. All material on this Site, including
        images, illustrations, audio clips, and video clips, is protected by
        copyrights, trademarks, and other intellectual property rights. The
        material is provided solely for your personal, non-commercial use. You
        may not copy, reproduce, republish, upload, post, transmit, or
        distribute such material in any way, including via email or other
        electronic means, whether directly or indirectly. You must not assist
        any other person or User in such activities. Without the prior written
        consent of the owner, any modification of the materials, use of the
        materials on any other website or networked computer environment, or use
        of the materials for any purpose other than personal, non-commercial
        use, constitutes a violation of copyrights, trademarks, and other
        proprietary rights and is strictly prohibited. Any use for which you
        receive any remuneration, whether in money or otherwise, is considered
        commercial use under this clause and requires express written
        permission.
      </p>
      <h2 className="text-2xl font-semibold mb-4">12. SERVICE PAYMENT</h2>
      <p className="mb-4">
        When using any of the payment methods available on the Amaxa Tech
        Solutions platform ("Website"), We will not be responsible for, nor
        assume any liability in respect of, any loss or damage arising directly
        or indirectly to You due to:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Lack of authorization for any transaction,</li>
        <li>
          Exceeding the preset limit mutually agreed upon between You and your
          issuing bank ("Bank"),
        </li>
        <li> Any issues or errors occurring during the payment process, or</li>
        <li>The decline of a transaction for any other reason</li>
      </ul>{" "}
      <p className="mb-4">
        All payments for purchases or services made on the Website by You must
        be in Indian Rupees, the legal tender of the Republic of India. Amaxa
        Tech Solutions does not facilitate transactions in any other currency.
        Prior to shipping or delivering any orders, We or the Seller may request
        that You provide supporting documentation (such as a government-issued
        ID or proof of address) to verify the ownership of the payment
        instrument used. This measure is taken to ensure a secure online
        environment for all Users. By using the Payment Facility provided on the
        Website, You expressly authorize Us and/or our service providers to
        collect, process, facilitate, and remit payments for transactions made
        through the Website, including payments made via Cash on Delivery. Your
        relationship with Us is based on a principal-to-principal arrangement,
        and by accepting these Terms of Use, You acknowledge that We act solely
        as an independent contractor. We have no control over or liability for
        the products or services listed on the Website that are paid for using
        the Payment Facility. Amaxa Tech Solutions does not guarantee the
        identity of any User, nor does it ensure that a Buyer or Seller will
        complete any transaction. You acknowledge, accept, and agree that the
        Payment Facility provided by Amaxa Tech Solutions is not a banking or
        financial service. Rather, it is a facilitator enabling electronic
        payments, including Cash on Delivery, using authorized banking
        infrastructure and credit/debit card or net banking payment gateways. We
        do not act as a trustee or in any fiduciary capacity with respect to the
        transaction or transaction price. We reserve the right to impose limits
        on the number or value of transactions processed through any credit
        card, debit card, cash card, bank account, or other financial
        instruments authorized by the Reserve Bank of India. Additionally, We
        reserve the right to refuse to process transactions that exceed such
        limits. We may also refuse to process transactions from Users with a
        history of questionable charges, including but not limited to breaches
        of agreements with Us, violations of applicable laws, or disputes raised
        by issuing banks or any other breaches of policy.
      </p>
      <h2 className="text-2xl font-semibold mb-4">
        13. BUYER OR USER’S PAYMENT ARRANGEMENT WITH ISSUING BANK
      </h2>
      <p className="mb-4">
        All valid credit, debit, cash cards, and other payment instruments used
        on Amaxa Tech Solutions are processed through a secure payment gateway
        or an appropriate payment system infrastructure. These transactions are
        subject to the terms and conditions agreed upon between You/User and the
        respective issuing bank or payment instrument provider. All online bank
        transfers from valid bank accounts are processed through the payment
        gateway provided by the respective issuing bank, which enables the
        payment facility to offer these services to you. Such online bank
        transfers are governed by the terms and conditions agreed upon between
        you and the respective issuing bank.
      </p>
      <h2 className="text-2xl font-semibold mb-4">14. INDEMNITY</h2>
      <p className="mb-4">
        By using Amaxa Tech Solutions, you agree to indemnify and hold harmless
        Amaxa Tech Solutions, its affiliates, subsidiaries, owners, officers,
        directors, agents, and employees from and against any and all claims,
        damages, liabilities, losses, expenses (including reasonable attorneys'
        fees), or penalties arising out of or related to:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          Your use of the Site, Products, or Services, or any violation of these
          Terms of Use, Privacy Policy, or other Policies.
        </li>
        <li>
          Any content or material you upload, post, or transmit on the platform,
          including but not limited to job postings, listings, messages, or
          other submissions
        </li>
        <li>
          Your breach of any third-party rights, including but not limited to
          intellectual property rights, privacy rights, or contractual
          obligations
        </li>
        <li>
          Your violation of any applicable laws, rules, or regulations in
          connection with your use of the Site or our Products and Services.
        </li>
        <li>
          Any unauthorized or improper use of the Site by you or anyone
          accessing the Site using your account.
        </li>
      </ul>
      <p className="mb-4">
        In addition, if you have any unpaid or outstanding invoice related to
        Our Products or Services, We reserve the right to suspend or terminate
        your access to or use of any of Our Products or Services until such
        outstanding amounts are settled.
      </p>
      <h2 className="text-2xl font-semibold mb-4">
        15. TERMINATION OF ACCOUNT{" "}
      </h2>
      <p className="mb-4">
        Amaxa Tech Solutions reserves the right to terminate or suspend your
        account for any violations of these Terms, illegal activities, or any
        other misuse of the platform. Upon termination, your access to the
        platform and its services will cease immediately, and any unpaid
        obligations will still be enforceable
      </p>
      <h2 className="text-2xl font-semibold mb-4">16. DISPUTE RESOLUTION </h2>
      <p className="mb-4">
        If any dispute arises between You and Amaxa Tech Solutions in connection
        with these Terms or the use of the Site, You agree to first attempt to
        resolve the issue informally by contacting Amaxa Tech Solutions. If the
        dispute cannot be resolved amicably, it shall be submitted to binding
        arbitration in India. The decision of the arbitrator will be final and
        binding.
      </p>
      <h2 className="text-2xl font-semibold mb-4">17. GOVERNING LAW </h2>
      <p className="mb-4">
        These Terms of Use are governed by and construed in accordance with the
        laws of India. Any disputes arising out of or related to the use of this
        website or these Terms will be resolved in the courts located in Valsad,
        Gujarat.
      </p>
      <h2 className="text-2xl font-semibold mb-4">
        18. REFUND AND CANCELLATION POLICY{" "}
      </h2>
      <p className="mb-4">
        Refunds for subscription services, if applicable, will be provided at
        the sole discretion of Amaxa Tech Solutions. Users may cancel their
        subscription services at any time, but no refunds will be issued for the
        unused portion of the subscription.
      </p>
      <h2 className="text-2xl font-semibold mb-4">
        19. MODIFICATIONS TO THE TERMS{" "}
      </h2>
      <p className="mb-4">
        Amaxa Tech Solutions reserves the right to change or update these Terms
        at any time without prior notice. It is the user’s responsibility to
        review these Terms regularly. Continued use of the Site following any
        updates constitutes acceptance of the new Terms.
      </p>
      <h2 className="text-2xl font-semibold mb-4">20. CONTACT INFORMATION </h2>
      <p className="mb-4">
        For any questions or concerns regarding these Terms, please contact
        Amaxa Tech Solutions at in.
      </p>
      <h2 className="text-2xl font-semibold mb-4">
        21. E-MAIL ABUSE & THREAT POLICY
      </h2>
      <p className="mb-4">
        At Amaxa Tech Solutions, we prioritize professional, respectful, and
        courteous communication between users, especially through private
        channels like email. While we do not regulate private communications
        such as email exchanges, we take certain types of misuse or abuse very
        seriously.
      </p>
      <p className="mb-4">
        Below are the instances of e-mail abuse that are not tolerated on our
        platform:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          Users are strictly prohibited from sending emails that contain
          explicit threats of bodily harm or violence. Amaxa Tech Solutions does
          not permit any form of communication that threatens or endangers
          another individual.
        </li>
        <li>
          Our communication tools are designed to facilitate legitimate business
          interactions. Any misuse of these tools, including manipulation or
          exploitation of the system, is subject to investigation and potential
          disciplinary action.
        </li>
        <li>
          Amaxa Tech Solutions will never request sensitive information (such as
          passwords, payment details, etc.) via email. If you receive any
          suspicious or spoof emails claiming to be from Amaxa Tech Solutions,
          we urge you to report them immediately through our "Contact Us" page.
        </li>
        <li>
          Our platform strictly prohibits users from sending unsolicited
          commercial emails (spam) to other users. Spamming is not only
          disruptive but also violates the acceptable use of our system. Users
          found sending unsolicited commercial messages will face disciplinary
          action.
        </li>
        <li>
          Amaxa Tech Solutions does not permit users to make or accept offers to
          buy or sell services or products outside the platform. Any attempt to
          transact outside of our platform poses a significant fraud risk and
          undermines the security and integrity of our system.
        </li>
      </ul>
      <h2 className="text-2xl font-semibold mb-4">
        Consequences for Violations
      </h2>
      <p className="mb-4">
        Users found in violation of this policy may face a range of actions,
        which include but are not limited to:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Restrictions on account privileges</li>
        <li>Suspension or termination of accounts</li>
        <li>Loss of special account status</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-4">Reporting E-mail Abuse</h2>
      <p className="mb-4">
        If you encounter any form of e-mail abuse or violations of this policy,
        we encourage you to report the incident immediately. Amaxa Tech
        Solutions is committed to investigating and addressing any issues
        swiftly to maintain a safe and respectful environment for all users.
      </p>
    </div>
  );
};

export default Terms;
